var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card-code', {
    attrs: {
      "title": "Light Badges"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeLightBadge) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', {
    staticClass: "mb-0"
  }, [_c('span', [_vm._v("Use ")]), _c('code', [_vm._v("variant")]), _c('span', [_vm._v(" prop for badge color variantion.")])]), _c('div', {
    staticClass: "demo-inline-spacing"
  }, [_c('b-badge', {
    attrs: {
      "pill": "",
      "variant": "light-primary"
    }
  }, [_vm._v(" Primary ")]), _c('b-badge', {
    attrs: {
      "pill": "",
      "variant": "light-secondary"
    }
  }, [_vm._v(" Secondary ")]), _c('b-badge', {
    attrs: {
      "pill": "",
      "variant": "light-success"
    }
  }, [_vm._v(" Success ")]), _c('b-badge', {
    attrs: {
      "pill": "",
      "variant": "light-danger"
    }
  }, [_vm._v(" Danger ")]), _c('b-badge', {
    attrs: {
      "pill": "",
      "variant": "light-warning"
    }
  }, [_vm._v(" Warning ")]), _c('b-badge', {
    attrs: {
      "pill": "",
      "variant": "light-info"
    }
  }, [_vm._v(" Info ")]), _c('b-badge', {
    attrs: {
      "pill": "",
      "variant": "light-dark"
    }
  }, [_vm._v(" Dark ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }