var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-row', [_c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-card-code', {
    attrs: {
      "title": "Link Badge"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeLinkBadge) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("Use ")]), _c('code', [_vm._v("href")]), _c('span', [_vm._v(" prop (links) or ")]), _c('code', [_vm._v("to")]), _c('span', [_vm._v(" prop (router-links) for link badges.")])]), _c('b-badge', {
    attrs: {
      "href": "#",
      "pill": "",
      "variant": "primary"
    }
  }, [_vm._v(" Primary ")])], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6"
    }
  }, [_c('b-card-code', {
    attrs: {
      "title": "Block Badge Pill"
    },
    scopedSlots: _vm._u([{
      key: "code",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.codeLinkBlock) + " ")];
      },
      proxy: true
    }])
  }, [_c('b-card-text', [_c('span', [_vm._v("Use ")]), _c('code', [_vm._v(".d-block")]), _c('span', [_vm._v(" class with ")]), _c('code', [_vm._v("<b-badge>")]), _c('span', [_vm._v(", to display pill badge as a block element.")])]), _c('b-badge', {
    staticClass: "d-block",
    attrs: {
      "href": "#",
      "pill": "",
      "variant": "danger"
    }
  }, [_vm._v(" Block Badge Pill ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }